import { create } from 'zustand'

const defaultProps = {
  notifications: [],
}

export const useNotificationsStore = create((set, get) => ({
  ...defaultProps,
  addNotification: (notification) => {
    set({
      notifications: [...get().notifications, notification],
    })
  },
  clearNotifications: () => {
    set({ ...defaultProps })
  },
}))
