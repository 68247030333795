import React from 'react'
import { Button, Logo, Theme, ScrollView, YStack } from '@bp/ui'
import { useAuth } from 'app/lib/auth'

export function Home() {
  const { login, signup, logout, user, isLoading } = useAuth()

  return (
    <Theme>
      <ScrollView fg={1}>
        <YStack gap="$10" jc="space-around" ai="center" px="$6" pt="$20" fg={1}>
          <Logo scale={3} />
          {!user && !isLoading ? (
            <YStack fg={1} mt={60} gap={30}>
              <Button
                width={250}
                onPress={() => {
                  login()
                }}
                variant="primary"
              >
                Login
              </Button>
              <Button
                variant="link"
                onPress={() => {
                  signup()
                }}
                color="$dark"
              >
                Signup
              </Button>
            </YStack>
          ) : (
            <YStack fg={1} mt={60} gap={30}>
              <Button
                width={250}
                onPress={() => {
                  logout()
                }}
                variant="primary"
              >
                Logout
              </Button>
            </YStack>
          )}
        </YStack>
      </ScrollView>
    </Theme>
  )
}
