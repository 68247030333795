import { Text, YStack, Theme, ScrollView, Button } from '@bp/ui'
import React from 'react'
import Protected from '../layout/protected'
import { useNotificationsStore } from 'app/store'
import { ChevronRight } from '@tamagui/lucide-icons'
import { useLink } from 'solito/link'
import { useUserInfoStore } from 'app/store'
import Feed from './feed'
import NewPost from './newPost'

export default function Feeds({ label }) {
  const notifications = useNotificationsStore((s) => s.notifications)

  const wstest1Link = useLink({
    href: '/user/wstest1',
  })
  const wstest2Link = useLink({
    href: '/user/wstest2',
  })
  const wstest6Link = useLink({
    href: '/user/wstest6',
  })

  const userId = useUserInfoStore((s) => s.id)

  return (
    <Theme name="light">
      <ScrollView bc="$background">
        <Protected>
          <YStack justifyContent="center">
            <YStack p="$4">
              {label && <Text variant="H1">{label}</Text>}
              <NewPost userId={userId} feedSlug="timeline_aggregated" />
              <Feed userId={userId} feedSlug="timeline_aggregated" />
            </YStack>
          </YStack>
        </Protected>
      </ScrollView>
    </Theme>
  )
}
