import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M2.00952 21L22 12.5L2.00952 4L2 10.6111L16.2857 12.5L2 14.3889L2.00952 21Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'SendIcon'

export const SendIcon = memo<IconProps>(themed(Icon))
